import { Show } from "solid-js";
import Main from "~/components/Main";
import { createClient, parseAccessToken } from "~/supabase";
import { Database } from "~/supabase-types";
import styles from "./menu.module.css";
import { A, cache, createAsync } from "@solidjs/router";

const getFlags = cache(async () => {
  const supabase = createClient();
  const session = await supabase.auth.getSession();
  if (session == null || session.error || session.data.session == null) {
    console.error(`Session data is null`, session);
    return {
      showCashier: false,
      showSender: false,
      showReceiver: false,
    };
  }
  const token = parseAccessToken(session.data.session.access_token);
  const roles = token.user_metadata.companies[0]
    .roles as Database["public"]["Enums"]["role"][];

  if (roles.includes("ADMIN")) {
    return {
      showCashier: true,
      showSender: true,
      showReceiver: true,
    };
  }

  const flags = {
    showCashier: roles.includes("CASSA"),
    showSender: roles.includes("SENDER"),
    showReceiver: roles.includes("RECEIVER"),
  };

  return flags;
}, "flags");

export const route = {
  load: (params: { location: { pathname: string } }) => {
    return { location: params.location };
  },
};

export default function Home() {
  const roles = createAsync(() => getFlags());

  return (
    <Main backLink="/app/menu" showSignOutButton={true}>
      <section class={styles.roles}>
        <Show when={roles()?.showSender}>
          <A href="/app/sender/pick-table" class={styles.imageButton}>
            <img src="/images/waiter.webp" alt="Waiter" />
            <span>Cameriere</span>
          </A>
        </Show>
        <Show when={roles()?.showReceiver}>
          <A href="/app/receiver" class={styles.imageButton}>
            <img src="/images/barman.webp" alt="Barman" />
            <span>Barman</span>
          </A>
        </Show>
        <Show when={roles()?.showCashier}>
          <A href="/app/cashier" class={styles.imageButton}>
            <img src="/images/cashier.webp" alt="cashier" />
            <span>Cassa</span>
          </A>
        </Show>
      </section>
    </Main>
  );
}
