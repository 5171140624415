import { Component, DEV, createEffect, createSignal } from "solid-js";
import Button from "./Button";
import styles from "./ErrorFallback.module.css";

const ErrorFallback: Component<{ error: Error }> = (props) => {
  const [errorMessages] = createSignal([
    "👻 Oops! Credo che il codice abbia preso una pausa caffè.",
    "🔥 Qualcuno ha versato espresso sul codice!",
    "🐛 Questo bug davvero non vuole che lavori oggi, eh?",
    "💻 Non sono pigro, sono solo in modalità risparmio energetico. Riprova?",
    "⏳ Ho detto al codice di sbrigarsi a tornare, ma è bloccato nel traffico di Bologna!",
  ]);

  if (DEV) {
    createEffect(() => {
      console.error("Error caught by ErrorFallback: ", props.error);
    });
  }

  const randomMessage = () => {
    const messages = errorMessages();
    return messages[Math.floor(Math.random() * messages.length)];
  };

  return (
    <div class={styles.component}>
      <h2>Something went wrong...</h2>
      <h3>{randomMessage()}</h3>
      <Button onClick={() => location.reload()}>Try Again</Button>
    </div>
  );
};

export default ErrorFallback;
