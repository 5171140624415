import { DateTime } from "luxon";
import { type Order } from "~/common-types";
import { createEffect, For, Show } from "solid-js";
import { createStore } from "solid-js/store";
import styles from "./receiver.module.css";
import Main from "~/components/Main";
import {
  IoArrowBackCircle,
  IoArrowForwardCircle,
  IoCheckmarkCircle,
} from "solid-icons/io";
import Clock, { createClock } from "~/components/Clock";
import IconButton from "~/components/IconButton";
import { createClient } from "~/supabase";
import { Meta, Title } from "@solidjs/meta";
import { createAsync } from "@solidjs/router";

async function subscribeToOrders(
  orders: Order[],
  setOrders: (orders: Order[]) => void,
) {
  const supabase = createClient();
  const ordersResp = await supabase
    .from("orders")
    .select(
      "*, orders_products(id, quantity, description, products(*)), tables(name)",
    )
    .filter("state", "in", "(TO_PREPARE,PREPARING)")
    .order("created_at", { ascending: true });
  if (ordersResp.error) {
    console.log("error fetching products", ordersResp.error);
    return;
  } else {
    // setOrders(
    //   ordersResp.data.map((order) => {
    //     return {
    //       order_id: order.id,
    //       created_at: order.created_at,
    //       table_name: order.tables!.name,
    //       state: order.state,
    //       items: order.orders_products.map((item) => ({
    //         id: item.id,
    //         product_id: item.products?.id,
    //         product_name: item.products?.name,
    //         description: item.description,
    //         quantity: item.quantity,
    //         price: item.products?.price || item.products?.price_bottle || 0,
    //       })),
    //     };
    //   }),
    // );
  }

  supabase
    .channel("orders_changes")
    .on(
      "postgres_changes",
      {
        event: "*",
        schema: "public",
        table: "orders",
      },
      async (payload) => {
        if (payload.new) {
          const order = await supabase
            .from("orders")
            .select(
              "*, orders_products(id, quantity, description, products(*)), tables(name)",
            )
            .eq("id", (payload.new as any).id)
            .single();
          if (order.error) {
            console.log("error fetching products", order.error);
          } else {
            // setOrders(
            //   [
            //     ...orders,
            //     {
            //       order_id: order.data.id,
            //       created_at: order.data.created_at,
            //       table_name: order.data.tables!.name,
            //       state: order.data.state,
            //       items: order.data.orders_products.map((item) => ({
            //         id: item.id,
            //         product_id: item.products?.id,
            //         product_name: item.products?.name,
            //         description: item.description,
            //         quantity: item.quantity,
            //         price:
            //           item.products?.price || item.products?.price_bottle || 0,
            //       })),
            //     },
            //   ].sort(
            //     (a, b) =>
            //       DateTime.fromISO(a.created_at).valueOf() -
            //       DateTime.fromISO(b.created_at).valueOf(),
            //   ),
            // );
          }
        }
      },
    )
    .subscribe();
}

function routeData() {
  const supabase = createClient();
  const [orders, setOrders] = createStore<Order[]>([]);
  const ordersToPrepare = () => orders.filter((o) => o.state === "TO_PREPARE");
  const ordersPreparing = () => orders.filter((o) => o.state === "PREPARING");

  createEffect(() => subscribeToOrders(orders, setOrders));

  async function setOrderState(orderId: string, state: Order["state"]) {
    const orderStateBeforeOptimisticUpdate = orders.find(
      (o) => o.order_id === orderId,
    )!.state;
    setOrders((orders) =>
      orders.map((o) =>
        o.order_id === orderId ? { ...o, state: "READY_TO_DELIVER" } : o,
      ),
    );
    const createOrderResult = await supabase
      .from("orders")
      .update({ state })
      .eq("id", orderId)
      .select();
    if (createOrderResult.error) {
      console.log(
        `error updating order ${orderId} to state READY_TO_DELIVER`,
        createOrderResult.error,
      );
      // revert optimistic update
      setOrders((orders) =>
        orders.map((o) =>
          o.order_id === orderId
            ? { ...o, state: orderStateBeforeOptimisticUpdate }
            : o,
        ),
      );
    }
  }

  async function setOrderReady(orderId: string) {
    return setOrderState(orderId, "READY_TO_DELIVER");
  }

  async function setOrderPreparing(orderId: string) {
    return setOrderState(orderId, "PREPARING");
  }

  async function setOrderToPrepare(orderId: string) {
    return setOrderState(orderId, "TO_PREPARE");
  }

  return {
    ordersToPrepare,
    ordersPreparing,
    setOrderReady,
    setOrderToPrepare,
    setOrderPreparing,
  };
}

export const route = {
  load: () => routeData(),
};

export default function Receiver() {
  const clock = createClock();
  const result = createAsync(async () => routeData());
  const {
    ordersToPrepare,
    ordersPreparing,
    setOrderPreparing,
    setOrderToPrepare,
    setOrderReady,
  } = result() ?? {
    ordersToPrepare: () => [],
    ordersPreparing: () => [],
    setOrderPreparing: (_: string) => {},
    setOrderToPrepare: (_: string) => {},
    setOrderReady: (_: string) => {},
  };

  const [selectedOrderIds, setSelectedOrderIds] = createStore<{
    [id: string]: boolean;
  }>({});
  const selectedOrdersCount = () =>
    Object.values(selectedOrderIds).filter((v) => v).length;
  const groupedOrdersByProductAndQuantity = () =>
    ordersPreparing()
      .filter((o) => selectedOrderIds[o.order_id])
      .reduce<{
        [description: string]: {
          description: string;
          quantity: number;
        };
      }>((acc, order) => {
        order.items.forEach((item) => {
          const description = item.description;
          if (!acc[description]) {
            acc[description] = {
              description: item.description,
              quantity: 0,
            };
          }
          acc[description].quantity += item.quantity;
        });
        return acc;
      }, {});

  return (
    <>
      <Title>Receiver</Title>
      <Meta name="description" content="Bar Flemming - Receiver" />
      <Main title="Receiver" backLink="/app/menu">
        <section class={styles.clock}>
          <Clock />
        </section>
        <div class={styles.container}>
          <section class={styles.orderByTime}>
            <For
              each={ordersToPrepare()}
              fallback={
                <div class={styles.noOrderMessage}>
                  There no other orders to be prepared
                </div>
              }
            >
              {(order) => (
                <div class={styles.order}>
                  <div
                    class={`${styles.orderTitle} ${styles.orderByTimeTitle}`}
                  >
                    <span>{order.table_name}</span>
                    <span>
                      {DateTime.fromJSDate(clock())
                        .diff(DateTime.fromISO(order.created_at))
                        .toFormat("hh:mm")}
                    </span>
                    <span>({order.items.length})</span>
                    <IconButton
                      onClick={() => {
                        setSelectedOrderIds(order.order_id, false);
                        setOrderPreparing(order.order_id);
                      }}
                    >
                      <IoArrowForwardCircle />
                    </IconButton>
                  </div>
                </div>
              )}
            </For>
          </section>
          <section class={styles.desktop}>
            <For
              each={ordersPreparing()}
              fallback={
                <div class={styles.noOrderMessage}>
                  You don't have any order on your desktop
                </div>
              }
            >
              {(order) => (
                <div
                  classList={{
                    [styles.order]: true,
                    [styles.orderSelected]: selectedOrderIds[order.order_id],
                  }}
                  onClick={() => {
                    setSelectedOrderIds((selectedOrderIds) => ({
                      ...selectedOrderIds,
                      [order.order_id]: !selectedOrderIds[order.order_id],
                    }));
                  }}
                >
                  <h2 class={styles.orderTitle}>
                    <IconButton
                      onClick={() => {
                        setSelectedOrderIds(order.order_id, false);
                        setOrderToPrepare(order.order_id);
                      }}
                    >
                      <IoArrowBackCircle />
                    </IconButton>
                    <span>{order.table_name}</span>
                    <span>
                      {DateTime.fromJSDate(clock())
                        .diff(DateTime.fromISO(order.created_at))
                        .toFormat("hh:mm")}
                    </span>
                    <IconButton
                      onClick={() => {
                        setSelectedOrderIds(order.order_id, false);
                        setOrderReady(order.order_id);
                      }}
                    >
                      <IoCheckmarkCircle />
                    </IconButton>
                  </h2>
                  <ul class={styles.orderItems} role="list">
                    {order.items.map((item) => (
                      <li class={styles.orderItem}>
                        <span class={styles.orderItemCount}>
                          {item.quantity}
                        </span>
                        {/* <Show
                          when={isWine(item.description)}
                          fallback={<span>{item.description}</span>}
                        >
                          <span class={styles.description}>
                            {WineDescription.getType(item.description!) ===
                            WineType.BOTTLE ? (
                              <FaSolidWineBottle />
                            ) : (
                              <FaSolidWineGlass />
                            )}
                            <span>{item.product_name}</span>
                          </span>
                        </Show> */}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </For>
          </section>
          <Show when={selectedOrdersCount() >= 2}>
            <section class={styles.aggregateOrder}>
              <For each={Object.values(groupedOrdersByProductAndQuantity())}>
                {(item) => (
                  <div class={styles.orderItem}>
                    <span class={styles.orderItemCount}>{item.quantity}</span>
                    <span class={styles.description}>
                      {/* <Show
                        when={isWine(item.description)}
                        fallback={<span>{item.description}</span>}
                      >
                        {WineDescription.getType(item.description!) ===
                        WineType.BOTTLE ? (
                          <FaSolidWineBottle />
                        ) : (
                          <FaSolidWineGlass />
                        )}
                        <span>
                          {WineDescription.getDescriptor(item.description)}
                        </span>
                      </Show> */}
                    </span>
                  </div>
                )}
              </For>
            </section>
          </Show>
        </div>
      </Main>
    </>
  );
}
