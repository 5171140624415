import { MetaProvider } from "@solidjs/meta";
import { Router } from "@solidjs/router";
import { FileRoutes } from "@solidjs/start/router";
import { ErrorBoundary, Suspense, children, lazy } from "solid-js";

import "./app.css";
import ErrorFallbackComponent from "./components/ErrorFallback";

export default function App() {
  return (
    <Router
      root={(props) => (
        <MetaProvider>
          <ErrorBoundary fallback={ErrorFallbackComponent}>
            <Suspense>{props.children}</Suspense>
          </ErrorBoundary>
        </MetaProvider>
      )}
    >
      <FileRoutes />
    </Router>
  );
}
